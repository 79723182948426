import React from 'react'
import { View, Image, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { Heading, Label } from 'src/design-system/components/Text'
import { FontFamilies } from 'src/design-system/theme/typography/base'
import { useBreakpoint } from 'src/hocs/breakpoint'

type Props = {
  title: string
  subtitle?: string
  alwaysShowLogo?: boolean
  style?: StyleProp<ViewStyle>
}

export const AuthHeader: React.FC<Props> = ({
  title,
  subtitle,
  style,
  alwaysShowLogo,
}) => {
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const showLogo = !(isExSmallScreen || isSmallScreen) || alwaysShowLogo

  return (
    <View style={[styles.container, style]}>
      {showLogo ? (
        <Image
          source={require('assets/images/vetradar-icon.png')}
          resizeMode="contain"
          style={styles.logoIcon}
        />
      ) : null}
      <Heading size={'XL'} style={[styles.boldFont]}>
        {title}
      </Heading>
      <Label size="L">{subtitle}</Label>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    gap: 8,
  },
  logoIcon: {
    width: 50,
    height: 50,
  },
  boldFont: {
    fontFamily: FontFamilies.bold,
  },
})

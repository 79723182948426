import { Auth } from 'aws-amplify'
import { SyncStorage } from 'src/utils/SyncStorage'
import { ORGANISATION_ID } from 'constants/Memory'
import { algo, SHA256, enc } from 'crypto-js'
import {
  Auth as VAuth,
  authTypeService,
  userSessionService,
} from 'src/context/auth'
import { reloadApp } from './reloadApp'

export type SwitchableUser = {
  email: string
  username: string
}

export class PINSwitchService {
  // Warning: This is highly dependent on the implementation of the Cognito SDK 😓
  currentUser: any
  // @ts-ignore
  users: { [email: string]: any }
  // @ts-ignore
  organisationId: string

  // TODO: Delete now = Remove this method in the future once all users has reset their Pins
  static pinHashV1(pin: string): number {
    let hash = 0
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < pin.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = pin.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
  }

  static pinHashV2(pin: string, email: string): string {
    const sha256 = algo.SHA256.create()
    sha256.update(pin)
    sha256.update(SHA256(email))

    return sha256.finalize().toString(enc.Base64)
  }

  static isPinV1(pin: string | number): boolean {
    return typeof pin === 'number'
  }

  async init() {
    this.currentUser = await Auth.currentUserPoolUser()
    try {
      this.users = JSON.parse(this.currentUser.storage.getItem('users')) ?? {}
    } catch (e) {
      this.users = {}
    }
    this.updateToken()
  }

  unInit() {
    this.currentUser = null
    this.users = null as any
    this.organisationId = ''
  }

  hasPinByEmail(email: string) {
    return !!this.users[email]?.pin
  }

  hasSkippedPin() {
    return this.users[this.currentUser.attributes.email].pin === null
  }

  hasPin() {
    return !!this.users[this.currentUser.attributes.email].pin
  }

  skipPin() {
    this.users[this.currentUser.attributes.email].pin = null
    this.persist()
  }

  setPin(pin: string) {
    this.users[this.currentUser.attributes.email].pin =
      PINSwitchService.pinHashV2(pin, this.currentUser.attributes.email)
    this.persist()
  }

  resetPin(email?: string) {
    if (email) {
      delete this.users[email]
    } else {
      // should only be no email when using superuser, clear all users
      this.users = {}
    }
    this.persist()
  }

  setCurrentUser(email: string) {
    this.clearStorage()
    Object.keys(this.users[email]).map(key => {
      if (key.startsWith('CognitoIdentityServiceProvider')) {
        this.currentUser.storage.setItem(key, this.users[email][key])
      }
    })
  }

  async resetAndDeleteUser(email: string, deletedUserEmail: string) {
    delete this.users[deletedUserEmail]

    this.setCurrentUser(email)
    await this.init()
  }

  switchUser(email: string, pin: string) {
    if (!this.isPinValid(email, pin)) {
      return false
    }
    this.setCurrentUser(email)

    return true
  }

  // TODO： Remove the isSkip parameter once we delete the user session
  async setOrganisationId(organisationId: string) {
    const isAuthV2 = authTypeService.getIsAuthV2()
    if (!isAuthV2) {
      const email = this.currentUser.attributes.email
      this.users[email].organisationId = organisationId
    }

    this.organisationId = organisationId
    this.persist()
    // If users login via AuthV1 and select a new organisation, we need to checkAuthVersion again
    if (!isAuthV2) {
      VAuth.checkAuthVersion().then(() => {
        if (authTypeService.getIsAuthV2()) {
          // After login to AuthV2 organisation site via AuthV1, we need to reinitialize AuthV2 session
          userSessionService.init()
          reloadApp()
        }
      })
    }
  }

  // clear storage, but reserve user switch related
  clearStorage() {
    if (this.currentUser?.storage) {
      this.currentUser.storage.clear()
    }
    this.persist()
  }

  private updateToken() {
    const cognitoUserId = this.currentUser.username
    const keyPrefix = `CognitoIdentityServiceProvider.${this.currentUser.pool.getClientId()}`
    const idTokenKey = `${keyPrefix}.${cognitoUserId}.idToken`
    const accessTokenKey = `${keyPrefix}.${cognitoUserId}.accessToken`
    const refreshTokenKey = `${keyPrefix}.${cognitoUserId}.refreshToken`
    const clockDriftKey = `${keyPrefix}.${cognitoUserId}.clockDrift`
    const lastUserKey = `${keyPrefix}.LastAuthUser`
    const username = this.currentUser.attributes.name

    const email = this.currentUser.attributes.email
    if (!this.users[email]) {
      this.users[email] = {}
    }
    Object.assign(this.users[email], {
      username,
      [idTokenKey]: this.currentUser.storage.getItem(idTokenKey),
      [accessTokenKey]: this.currentUser.storage.getItem(accessTokenKey),
      [refreshTokenKey]: this.currentUser.storage.getItem(refreshTokenKey),
      [clockDriftKey]: this.currentUser.storage.getItem(clockDriftKey),
      [lastUserKey]: this.currentUser.storage.getItem(lastUserKey),
    })
    this.organisationId = this.users[email].organisationId || ''
    this.persist()
  }

  private isPinValid(email: string, pin: string) {
    if (PINSwitchService.isPinV1(this.users[email].pin)) {
      return PINSwitchService.pinHashV1(pin) === this.users[email].pin
    }

    return PINSwitchService.pinHashV2(pin, email) === this.users[email].pin
  }

  private persist() {
    SyncStorage.setItem(ORGANISATION_ID, this.organisationId)
    if (this.users) {
      this.currentUser.storage.setItem('users', JSON.stringify(this.users))
    }
    userSessionService.persist()
  }
}

export const pinSwitchService = new PINSwitchService()

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Layout } from '../components/Layout'
import { PinInput } from '../components/PinInput'
import {
  Auth,
  AUTH_STATE,
  AUTH_WEIGHT,
  ONE_MIN as BUFFER,
  useAuth,
  userPinSessionService,
  userSessionService,
} from 'src/context/auth'
import { SkipAuthType } from 'src/context/auth/utils/type'
import { AuthPrimaryButton, AuthSecondaryButton } from '../components/Buttons'
import { RuleStatus } from 'src/design-system/components/TextInput/TextInput'
import { AuthHeader } from '../components/Header'

export const SetupPin: React.FC<PropsWithChildren> = () => {
  const { t } = useTranslation()
  const [pin, setPin] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])
  const { toggleAuthState } = useAuth()

  const handleToggleAuthState = useCallback(() => {
    userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
    toggleAuthState(AUTH_STATE.LOGIN, true)
    toggleAuthState(AUTH_STATE.UNLOCK_WITH_PIN, true)
  }, [toggleAuthState])

  const handleConfirmPin = useCallback(async () => {
    setIsLoading(true)
    const result = await Auth.setUpPin(pin)
    if (!!result?.errorCode) {
      return
    }

    handleToggleAuthState()
  }, [pin, handleToggleAuthState])

  const handleSkip = useCallback(async () => {
    const result = await Auth.skipAuth(SkipAuthType.SET_UP_PIN)
    if (!!result?.errorCode) {
      return
    }

    handleToggleAuthState()
  }, [handleToggleAuthState])

  const handlePinInput = useCallback((value: string) => {
    // TODO: Verify if more logic is required, but otherwise we can just store into state
    setPin(value)
  }, [])

  useLayoutEffect(() => {
    const initPINSwitch = () => {
      const userSession = userSessionService.getUserSession()
      if (!!userSession) {
        const pinInfo = userPinSessionService.hasPin(
          userSession?.userAttributes?.id ?? '',
        )
        const isPinSkipped =
          new Date() <
          new Date(
            userSession?.userAttributes?.skip_setup_pin_exp || Date.now(),
          )
        if (!!pinInfo || isPinSkipped) {
          handleToggleAuthState()
        }
      }
    }
    initPINSwitch()
  }, [handleToggleAuthState])

  useEffect(() => {
    const expiredAt = userSessionService.getUserSession()?.pinInfo?.expiredAt
    let timeout = BUFFER * 14 // default 14 minutes= 15 - 1(Buffer)
    if (expiredAt) {
      timeout = new Date(expiredAt).getTime() - new Date().getTime() - BUFFER
    }
    const timer = setInterval(() => {
      handleToggleAuthState()
      clearInterval(timer)
    }, timeout)

    return () => clearInterval(timer)
  }, [handleToggleAuthState])

  const handlePinValidation = (results: RuleStatus[] | null) => {
    setErrors(results?.filter(r => !r.isValid).map(r => r.message) ?? [])
  }

  return (
    <Layout>
      <View style={styles.container}>
        <AuthHeader
          title={t('login:pin:setup')}
          subtitle={t('login:pin:description')}
        />
        <View style={styles.pinContainer}>
          <PinInput
            onChange={handlePinInput}
            onConfirm={handleConfirmPin}
            displayPinPolicies={true}
            onValidation={handlePinValidation}
          />
        </View>
        <AuthPrimaryButton
          ariaLabel="ConfirmPinBtn"
          onPress={() => handleConfirmPin().then(() => setIsLoading(false))}
          title={t('login:pin:confirm')}
          disabled={isLoading || errors.length > 0 || pin.length < 5}
          loading={isLoading}
        />
        <AuthSecondaryButton
          ariaLabel="SkipPinBtn"
          onPress={handleSkip}
          title={t('login:pin:skip')}
          disabled={isLoading}
        />
      </View>
    </Layout>
  )
}

const styles = StyleSheet.create({
  container: {
    gap: 8,
  },
  pinContainer: {
    marginVertical: 32,
  },
})

import React, { useEffect, useState } from 'react'
import {
  Animated,
  LayoutChangeEvent,
  Modal as ReactNativeModal,
  StyleProp,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native'
import { environment } from 'src/config'
import { Colors } from 'src/design-system/theme'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { Shadows } from 'constants/Shadows'

type Props = {
  visible: boolean
  onCloseModal: () => void
  canCloseModal?: boolean
  contentStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  children: React.ReactNode
}

type ModalContentDimension = {
  width: number
  height: number
}

export const Modal: React.FC<Props> = ({
  visible,
  onCloseModal,
  canCloseModal = true,
  contentStyle,
  style,
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(visible)

  useEffect(() => {
    if (visible) {
      return setIsModalOpen(true)
    }

    setTimeout(() => {
      setIsModalOpen(false)
    }, 225)
  }, [visible, setIsModalOpen])

  const [modalContentDimension, setModalContentDimension] =
    useState<ModalContentDimension>({
      width: 0,
      height: 0,
    })

  const onModalContentLayout = (e: LayoutChangeEvent) => {
    const { width, height } = e.nativeEvent.layout

    setModalContentDimension({
      width,
      height,
    })
  }

  const onBackdropPress = () => {
    if (!canCloseModal) return

    onCloseModal()
  }

  const [slideAnimation] = useState(new Animated.Value(0))

  const [fadeAnimation] = useState(new Animated.Value(0))

  useEffect(() => {
    visible ? fadeAnimation.setValue(0) : fadeAnimation.setValue(1)
    Animated.timing(fadeAnimation, {
      toValue: visible ? 1 : 0,
      duration: 250,
      useNativeDriver: false,
    }).start()
  }, [fadeAnimation, visible])

  useEffect(() => {
    slideAnimation.setValue(0)
    Animated.timing(slideAnimation, {
      toValue: 1,
      duration: 250,
      useNativeDriver: false,
    }).start()
  }, [slideAnimation, visible])

  const { height } = useBreakpoint()

  return (
    <ReactNativeModal visible={isModalOpen} transparent={true}>
      <Animated.View
        style={[styles.modalContainer, style, { opacity: fadeAnimation }]}
      >
        <TouchableWithoutFeedback onPress={onBackdropPress}>
          <View style={styles.modalBackdrop} />
        </TouchableWithoutFeedback>

        <Animated.View
          onLayout={onModalContentLayout}
          style={[
            styles.modalContentOuter,
            {
              transform: [
                { translateX: -modalContentDimension.width / 2 },
                { translateY: -modalContentDimension.height / 2 },
                {
                  translateY: slideAnimation.interpolate({
                    inputRange: [0, 1],
                    outputRange: visible ? [height, 0] : [0, height],
                  }),
                },
              ],
            },
            contentStyle,
          ]}
        >
          <View style={[styles.modalContentInner]}>{children}</View>
        </Animated.View>
      </Animated.View>
    </ReactNativeModal>
  )
}

const contentBorderRadius = environment.isWeb ? 4 : 16

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    position: 'absolute',
    zIndex: -100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  modalBackdrop: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
  },
  modalContentOuter: {
    backgroundColor: Colors.Backgrounds.UI,
    borderRadius: contentBorderRadius,
    zIndex: 100,
    left: '50%',
    top: '50%',
    width: '80%',
    ...Shadows.cardShadow,
  },
  modalContentInner: {
    overflow: 'hidden', // Needed to clip the children to the parent border radius without removing boxShadow
    borderRadius: contentBorderRadius,
  },
})

export interface AuthError {
  errorCode: ErrorCode
  message?: string
  support?: {
    message: string
    errorID?: string
  }
}

export interface NewAuthChallenge {
  ChallengeName: string
  Session: string
  isPasswordExpired: boolean
  ChallengeParameters: {
    userAttributes: string
  }
}

export interface AuthenticationResult {
  idToken: string
  refreshToken: string
  accessToken: string
  expiredAt: string
  tokenType: string
}

export interface UserInfo {
  id: string
  cognito_user_id: string
  email: string
  name: string
  email_verified: boolean
  role: string
  password_expired_at?: string | undefined
  unlock_sign_in_at?: string | undefined
  skip_setup_pin_exp?: string | undefined
  skip_verifyemail_exp?: string | undefined
  skip_change_pwd_exp?: string | undefined
  'cognito:groups'?: string[] | undefined
}

export interface PinInfo {
  token: string
  expiredAt: string
}

export interface UserSessionAPIData {
  authenticationResult?: AuthenticationResult
  userInfo?: UserInfo
  pinInfo?: PinInfo
}

export interface UserSession {
  errorCode?: ErrorCode
  authWeight?: number
  authFailures?: AuthFailures
  authenticationResult: AuthenticationResult
  pinInfo?: PinInfo
  pwdActionInfo?: PwdActionInfo
  userAttributes: UserInfo
}

export type AuthFailures = {
  [email: string]: string
}

export type LooseObj = {
  [key: string]: any
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

export interface FetchOptions {
  method?: HttpMethod
  payload?: LooseObj
  headers?: LooseObj
}

export enum PasswordAction {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  FORGET_PASSWORD = 'FORGET_PASSWORD',
  CHANGE_PASSWORD_IN_SESSION = 'CHANGE_PASSWORD_IN_SESSION',
}

export type PwdActionInfo = {
  action: PasswordAction
  email?: string
  session?: string
  isPasswordExpired?: boolean
}

export enum SkipAuthType {
  CHANGE_PASSWORD_WARNING = 'CHANGE_PASSWORD_WARNING',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  SET_UP_PIN = 'SET_UP_PIN',
}

export const skipAuthMap = {
  [SkipAuthType.CHANGE_PASSWORD_WARNING]: 'skip_change_pwd_exp',
  [SkipAuthType.VERIFY_EMAIL]: 'skip_verifyemail_exp',
  [SkipAuthType.SET_UP_PIN]: 'skip_setup_pin_exp',
}

export enum ErrorCode {
  // generic error codes
  UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR',
  REQUEST_BODY_ERROR = 'REQUEST_BODY_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  // error codes for auth
  ACCESS_TOKEN_ERROR = 'ACCESS_TOKEN_ERROR',
  REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_DISABLED = 'USER_DISABLED',
  USER_LINKING_ERROR = 'USER_LINKING_ERROR',
  USER_EMAIL_ALREADY_VERIFIED = 'USER_EMAIL_ALREADY_VERIFIED',
  PIN_NOT_FOUND = 'PIN_NOT_FOUND',
  PIN_EXPIRED = 'PIN_EXPIRED',
  PIN_MAX_ATTEMPT = 'PIN_MAX_ATTEMPT',
  PIN_INCORRECT = 'PIN_INCORRECT',
  PIN_SAME_NUMBERS_ERROR = 'PIN_SAME_NUMBERS_ERROR',
  PIN_SEQUENTIAL_NUMBERS_ERROR = 'PIN_SEQUENTIAL_NUMBERS_ERROR',
  SETUP_PIN_EXPIRED_PASSWORD = 'SETUP_PIN_EXPIRED_PASSWORD',
  PASSWORD_REQUIREMENT_ERROR = 'PASSWORD_REQUIREMENT_ERROR',
  CURRENT_PASSWORD_INCORRECT = 'CURRENT_PASSWORD_INCORRECT', // For change password in session
  PASSWORD_OR_EMAIL_INCORRECT = 'PASSWORD_OR_EMAIL_INCORRECT',
  PASSWORD_OR_EMAIL_MAX_ATTEMPT = 'PASSWORD_OR_EMAIL_MAX_ATTEMPT',
  VERIFICATION_CODE_INCORRECT = 'VERIFICATION_CODE_INCORRECT',
  VERIFICATION_CODE_EXPIRED = 'VERIFICATION_CODE_EXPIRED',
  TEMP_PASSWORD_EXPIRED_SESSION = 'TEMP_PASSWORD_EXPIRED_SESSION',
  // error codes in front-end only
  CHANGE_PASSWORD_IN_SESSION_MAX_ATTEMPT = 'CHANGE_PASSWORD_IN_SESSION_MAX_ATTEMPT',
}

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Auth } from 'aws-amplify'
import { toast } from 'components/common'
import { find, noop } from 'lodash'
import flagsmith from 'react-native-flagsmith'
import { GET_USER } from 'src/components/Settings/graphql'
import { useClientQueryPromise } from 'src/utils/useClientQueryPromise'
import { getUser, getUser_getUser, getUserVariables } from 'types/getUser'
import { ApprovalRole, Roles } from 'types/globalTypes'

import { useOrganisation } from './organisation'
import { PreferencesService } from 'src/utils/userPreferenceService'
import { SyncStorage } from 'src/utils/SyncStorage'
import { signOut } from 'src/utils/signOut'
import { Auth as VAuth, authTypeService } from './auth'

const UserContext = React.createContext({
  user: null as getUser['getUser'],
  isAdmin: false,
  username: '',
  updateCurrentUser: noop as () => Promise<string>,
  approvalRole: ApprovalRole.NOT_APPLICABLE,
  preferences: undefined as PreferencesService | undefined,
})
UserContext.displayName = 'UserContext'

type Props = {
  children: React.ReactElement
}

export const UserProvider: React.FC<Props> = props => {
  const [username, setUsername] = useState('')
  const [{ organisationId, organisationChoices }] = useOrganisation()
  const client = useApolloClient()

  const getCurrentUserPromise = useClientQueryPromise<
    getUser,
    getUserVariables
  >({
    query: GET_USER,
    fetchPolicy: 'no-cache',
  })

  const [user, setUser] = useState<getUser_getUser | null | undefined>(null)

  const [preferences, setPreferences] = useState<PreferencesService>()
  useEffect(() => {
    user?.id && setPreferences(new PreferencesService(SyncStorage, user.id))
  }, [user])

  const updateCurrentUser = async () => {
    let cognitoUserInfo = {} as any
    const isAuthV2 = authTypeService.getIsAuthV2()
    if (isAuthV2) {
      cognitoUserInfo.attributes =
        (await VAuth.currentSession())?.userAttributes || {}
    } else {
      cognitoUserInfo = await Auth.currentUserInfo()
    }
    if (!cognitoUserInfo || !cognitoUserInfo.attributes) {
      kickUserOut(new Error('User is not logged in'))
      return ''
    }

    const result = await getCurrentUserPromise({
      id: isAuthV2
        ? cognitoUserInfo.attributes.id
        : cognitoUserInfo.attributes['custom:vetradar_user_id'],
      organisation_id: organisationId,
    })

    if (!result.data?.getUser) {
      kickUserOut(new Error('User info not found'))
      return ''
    }

    if (!user || !result.data.getUser.deleted_at) {
      setUsername(
        cognitoUserInfo.attributes.name || cognitoUserInfo.attributes.email,
      )
      setUser({
        ...result.data.getUser,
        role: result.data.getUser.role,
      })
      return result.data.getUser.email
    }

    return user.email
  }

  const kickUserOut = useCallback(
    (error?: Error) => {
      toast.error(error?.toString() ?? 'User not found')
      flagsmith.logout()
      client.clearStore()
      signOut()
    },
    [client],
  )

  useEffect(() => {
    try {
      updateCurrentUser()
    } catch (e) {
      kickUserOut(e as Error)
    }
    return () => {
      flagsmith.stopListening()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user?.id && user?.email && organisationId) {
      const chosenOrganisationName = find(
        organisationChoices,
        org => org.id === organisationId,
      )
      const flagsmithID = `${user.email} ${user.id}`
      flagsmith.identify(flagsmithID, {
        // We only set this to one org now since too many causes a 500 when > 2KB
        organisations: chosenOrganisationName?.name ?? '',
        role: user?.role,
      })
    }
  }, [user?.role, organisationChoices, organisationId, user?.id, user?.email])

  const value = {
    user: user ?? null,
    isAdmin: user?.role === Roles.ADMIN,
    username,
    updateCurrentUser,
    approvalRole: user?.approval_role ?? ApprovalRole.NOT_APPLICABLE,
    preferences,
  }
  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)

import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native'
import { DialogHeaderProps } from './Dialog.types'
import { useTranslation } from 'react-i18next'
import { Colors, Typography } from 'src/design-system/theme'
import { SvgClose } from 'components/Icons/Close'

export const enum DialogHeights {
  SMALL = '40%',
  MEDIUM = '60%',
  BIG = '95%',
}

export const useDialog = () => {
  const [visible, setVisibility] = useState(false)

  function toggleDialog() {
    setVisibility(!visible)
  }

  return {
    visible,
    toggleDialog,
  }
}

export const DialogHeader = ({
  bgColor,
  onCancel,
  onSave,
  showCancel = true,
  showDone = true,
  title,
}: DialogHeaderProps) => {
  const { t } = useTranslation()
  return (
    <View
      testID="Dialog Header"
      style={[
        styles.container,
        { ...(bgColor && { backgroundColor: bgColor }) },
      ]}
    >
      <View style={styles.btnContainer}>
        {!!showCancel && (
          <TouchableOpacity
            style={styles.btn}
            onPress={onCancel}
            accessibilityLabel={t('general.cancel')}
          >
            <SvgClose
              length={styles.btnText.fontSize}
              color={Colors.Contents.primary}
            />
          </TouchableOpacity>
        )}
      </View>
      {!!title && (
        <View style={styles.titleContainer}>
          <Text style={styles.titleText} numberOfLines={1} ellipsizeMode="tail">
            {title}
          </Text>
        </View>
      )}
      <View style={[styles.btnContainer, { justifyContent: 'flex-end' }]}>
        {!!showDone && (
          <TouchableOpacity
            style={styles.btn}
            onPress={onSave}
            accessibilityLabel={t('general.done')}
          >
            <Text numberOfLines={1} style={styles.btnText}>
              {t('general.done')}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    backgroundColor: Colors.Backgrounds.primary,
    padding: 4,
    position: 'relative',
    left: 0,
    top: 0,
    height: 56,
  },
  titleContainer: {
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1, // flexBasis: 0, grow:1 means the title appears centered, no matter the size of the containers
    flexBasis: 0,
  },
  btn: {
    padding: 16,
  },
  btnText: {
    color: Colors.Contents.accent,
    fontSize: Typography.FontSizes.md,
    fontWeight: '500',
  },
  titleText: {
    color: Colors.Contents.primary,
    fontSize: Typography.FontSizes.md,
    fontWeight: '700',
  },
})

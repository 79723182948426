import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react'
import {
  Button,
  DateTimePicker,
  FormBreak,
  InformativeMessage,
  InputGroup,
  InputGroupText,
  NumericInput,
  Select,
  Status,
  SwitchInput,
  TextArea,
  TextInput,
  TextLink,
  toast,
} from 'components/common'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { TreatmentChildLevel } from 'components/Treatment/common/types'
import { Colors } from 'constants/Colors'
import { Colors as dsColors } from 'src/design-system/theme'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { isEmpty, omit } from 'lodash'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Pressable, View, Text } from 'react-native'
import flagsmith from 'react-native-flagsmith'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { usePatientById } from 'src/hooks/usePatient'
import { useRound } from 'src/hooks/useRound'
import {
  ApprovalRole,
  CubexTransactionStatus,
  PermissionAction,
  ProductType,
  Status as StatusEnum,
} from 'types/globalTypes'

import {
  PermissionResetButton,
  PermissionTextLink,
} from 'components/common/Permission'

import { SubmitButton } from './common/SubmitButton'
import { styles } from './common/taskTransitionStyles'
import { TaskFileAttachments } from './TaskFileAttachments'
import {
  Action,
  AttachmentInformation,
  GetCompleteTaskInput,
  GetDoneOrProgressTaskInput,
  GetEditTaskInput,
  Inputs,
  Task,
  TaskTransitionProps,
} from './types'
import { getTaskIsDeletable } from './utils/getTaskIsDeletable'
import { getTaskUnit } from './utils/getTaskUnit'
import { getFormValueOrTotalDosage } from './utils/getTotalPrescribedDosage'
import { getTreatmentInfo } from './utils/getTreatmentInfo'
import { getUpdateTaskInput } from './utils/getUpdateTaskInput'
import { getValueInputType, ValueInputType } from './utils/getValueInputType'
import {
  buttonMinWidth,
  getIsTaskDoneOrProgress,
  getIsTaskMissedOrPendingOrDue,
  getIsTaskSkipDisabledWithMandatoryNotes,
} from './utils/taskTransitionUtils'
import { useDeleteTask } from './utils/useDeleteTask'
import { useRescheduleTask } from './utils/useRescheduleTask'
import { useTaskTransition } from './utils/useTaskTransition'
import { useUpdateTask } from './utils/useUpdateTask'
import { SaveNotesAndColorButton } from './SaveNotesButton'
import { WarningType } from 'components/common/DateTimePicker/index.types'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import { formatDuration, intervalToDuration } from 'date-fns'
import { getProductIsHealthStatusWeight } from './utils/getProductInfo'
import { regexToNumber } from 'components/Treatment/utils/allowNumberAndOnlyOneDot'
import { useUser } from 'src/context/user'
import { useApprovals } from 'src/hooks/useApprovals'
import { ColorPicker } from 'components/shared/ColorPicker'
import { useCacheTreatment } from 'components/Treatment/utils/useCacheTreatment'
import { BillingSection } from './common/BillingSection'
import { SvgArrowWithStem } from 'src/components/Icons/ArrowWithStem'
import { withPermission } from 'src/hocs'
import { usePermission } from 'src/hooks/usePermission'
import { retrieveVitalProductOptions } from 'src/utils/retrieveVitalProductOptions'
import { VitalRanges } from 'constants/VitalOptions'
import { checkValueIsOutsideOfRange } from './utils/checkValueIsOutsideOfRange'
import { usePatientCallParameters } from 'src/hooks/usePatientCallParameters'

const PermissionPressable = withPermission(Pressable)

const modifyNotesForCompletingMedication = ({
  treatment,
  notes,
}: {
  treatment: TreatmentChildLevel | null
  notes?: string | null
}) => {
  if (!treatment) {
    return notes
  }

  let modifiedNotes = notes ?? ''
  const shouldAddRouteOfAdminToNotes = flagsmith.hasFeature(
    FeatureFlagNames.AddRouteOfAdminToNotes,
  )

  if (
    shouldAddRouteOfAdminToNotes &&
    treatment?.medicine_dosage_info?.route_of_administration
  ) {
    const routeOfAdmin = `Route of Administration: ${treatment?.medicine_dosage_info?.route_of_administration}`
    modifiedNotes = modifiedNotes
      ? `${modifiedNotes}
${routeOfAdmin}`
      : routeOfAdmin
  }
  return modifiedNotes
}

export const attachmentInfo = (
  treatment: TreatmentChildLevel | null,
  task: Task | undefined,
): AttachmentInformation => {
  return {
    name: treatment?.product?.name,
    startedAt: task?.start_at ?? '',
  }
}

export const TaskTransitionWithoutRef: ForwardRefRenderFunction<
  TaskTransitionRef,
  TaskTransitionProps
> = (
  {
    action = Action.COMPLETE,
    createAndCompleteTask,
    isFinalized = false,
    isTaskPastEditingTimeout = false,
    patientId,
    task,
    toggleDialog,
    treatment,
    sheetId,
    activityLog,
    timeInGrid,
  },
  ref,
) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const patient = usePatientById(patientId)
  const deleteTask = useDeleteTask()
  const round = useRound()
  const updateTask = useUpdateTask()
  const rescheduleTask = useRescheduleTask()
  const hasQtyBilledPermission = usePermission(
    PermissionAction.TASK_BILLING__CHANGE,
  )
  const isSmallToLargeDisplay =
    breakpoint.isExSmallScreen ||
    breakpoint.isSmallScreen ||
    breakpoint.isMediumScreen ||
    breakpoint.isLargeButNotThatLargeScreen
  const {
    control,
    errors,
    getValues,
    handleIsBillable,
    handleQtyBilled,
    handlePhotoChange,
    handleVideoChange,
    isBackdated,
    isMedication,
    isReadOnlyAction,
    isUploadingMedia,
    isValueEqualQtyBilled,
    isWeb,
    mustCompleteWithInitials,
    mustCompleteWithValue,
    objectKeyPrefix,
    onReset,
    organisationId,
    patientWeightUnit,
    setIsUploadingMedia,
    setValue,
    shouldDisableSubmit,
    shouldShowBackDate,
    shouldShowBillableToggle,
    shouldShowUploadBtn,
    watchedValue,
  } = useTaskTransition({
    action,
    isFinalized,
    patientId,
    task,
    toggleDialog,
    treatment,
    sheetId,
    activityLog,
    timeInGrid,
  })

  const getPatientCallParameterRangeByProductID =
    usePatientCallParameters(patientId)
  const callParamValue = getPatientCallParameterRangeByProductID(
    treatment?.product?.id,
  )
  const { settingsMap } = useOrgSettings()

  const taskEditingTimeoutValue: string = useMemo(() => {
    if (!isTaskPastEditingTimeout) return ''
    const organisationTimeoutMilliseconds = !!settingsMap?.TASK_EDITING_TIMEOUT
      ?.value
      ? parseInt(settingsMap.TASK_EDITING_TIMEOUT?.value, 10)
      : 0
    const { days, hours, minutes } = intervalToDuration({
      start: 0,
      end: organisationTimeoutMilliseconds,
    })
    return formatDuration({ days, hours, minutes }, { delimiter: ', ' })
  }, [isTaskPastEditingTimeout, settingsMap.TASK_EDITING_TIMEOUT?.value])

  const isTaskBilled = useMemo(
    () => getIsTaskDoneOrProgress(task?.status),
    [task?.status],
  )

  const isCompleteAction = action === Action.COMPLETE
  const isRescheduleAction = action === Action.RESCHEDULE

  const showInProgress =
    action === Action.COMPLETE && task?.status !== StatusEnum.IN_PROGRESS

  const treatmentProduct = treatment?.product ?? null

  const { customValueOptions, hasRange, isHealthStatusProduct, isObservation } =
    getTreatmentInfo(treatment)

  const customProductOptions = retrieveVitalProductOptions(
    customValueOptions,
    isHealthStatusProduct,
    treatmentProduct?.origin_id,
  )

  const taskUnit = getTaskUnit({
    patientWeightUnit,
    task,
    dosageInfo: treatment?.medicine_dosage_info,
    product: treatmentProduct,
  })

  const handleWeightValueChange = (val: string) => {
    if (val.endsWith('.')) {
      const splitVal = val.split('.')
      if (!splitVal[1]) setValue('value', splitVal[0], { shouldValidate: true })
    }
  }

  const handleUseValueAsQtyBilled = () => {
    if (!valueIsValidQtyBilled) return

    const newValue = Number(watchedValue)
    if (isNaN(newValue)) return
    handleQtyBilled(Number(watchedValue))
  }

  const getCreateAndDoneOrProgressInput = useCallback(
    ({ newValue, inputs, isInProgress }: GetDoneOrProgressTaskInput) => {
      const taskInput = {
        value: newValue,
        ...(shouldShowBillableToggle && {
          send_to_adaptor: { is_billable: inputs.isBillable },
        }),
        ...(inputs.assigned_user && { assigned_user: inputs.assigned_user }),
        notes: inputs.notes,
        unit: getTaskUnit({
          patientWeightUnit,
          dosageInfo: treatment?.medicine_dosage_info,
          product: treatment?.product,
          task: inputs,
        }),
        status: isInProgress ? StatusEnum.IN_PROGRESS : StatusEnum.DONE,
        highlight_colour: inputs.highlightColor,
        qty_billed: inputs.qtyBilled,
      }
      if (isMedication) {
        taskInput.notes = modifyNotesForCompletingMedication({
          treatment,
          notes: taskInput.notes,
        })!
      }
      return taskInput
    },
    [isMedication, patientWeightUnit, shouldShowBillableToggle, treatment],
  )

  const getCompleteTaskInput = useCallback(
    ({ updatedTask, isInProgress }: GetCompleteTaskInput) => {
      const taskInput = {
        ...updatedTask,
        status: isInProgress ? StatusEnum.IN_PROGRESS : StatusEnum.DONE,
      }

      if (isMedication) {
        taskInput.notes = modifyNotesForCompletingMedication({
          treatment,
          notes: taskInput.notes,
        })
      }
      return taskInput
    },
    [isMedication, treatment],
  )

  const getEditTaskInput = useCallback(
    ({ updatedTask, originalTask }: GetEditTaskInput) => {
      const taskInput = {
        ...updatedTask,
        status:
          originalTask.status === StatusEnum.IN_PROGRESS
            ? StatusEnum.DONE
            : originalTask.status,
      }

      return { taskInput }
    },
    [],
  )
  const valueInputType = getValueInputType({ action, task, treatment })

  const renderValueInput = () => {
    switch (valueInputType) {
      case ValueInputType.Select:
        return (
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                a11yLabel={`${t(
                  'task:taskTransition:form:valueLabel',
                )} select list`}
                allowClear={true}
                dialog={false}
                label={t('task:taskTransition:form:valueLabel')}
                onChange={onChange}
                options={customProductOptions}
                placeholder={t('task:taskTransition:form:valueSelect')}
                required={mustCompleteWithValue}
                selected={value}
                style={styles.select}
                disabled={isReadOnlyAction}
              />
            )}
            name="value"
          />
        )
      case ValueInputType.Text:
        return (
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputGroup>
                {/* Not sure why but fragment required to grab textinput ref. BM */}
                <TextInput
                  autoFocus={true}
                  disabled={isReadOnlyAction || (isMedication && isTaskBilled)}
                  label={t('task:taskTransition:form:valueLabel')}
                  style={styles.flex}
                  required={mustCompleteWithValue}
                  onBlur={
                    getProductIsHealthStatusWeight(treatment?.product)
                      ? () => handleWeightValueChange(value)
                      : undefined
                  }
                  onChangeText={(val: string) => {
                    if (getProductIsHealthStatusWeight(treatment?.product)) {
                      onChange(regexToNumber(val))
                    } else onChange(val)
                  }}
                  value={value}
                />
                {taskUnit ? (
                  <InputGroupText
                    a11yLabel="Completion Value Unit"
                    text={taskUnit}
                  />
                ) : null}
                {hasRange ? (
                  <InputGroupText
                    a11yLabel="Completion Value Unit"
                    text={VitalRanges[treatmentProduct?.name ?? '']}
                  />
                ) : null}
              </InputGroup>
            )}
            name="value"
          />
        )
      case ValueInputType.Number:
        return (
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputGroup>
                <NumericInput
                  autoFocus={true}
                  disabled={isReadOnlyAction}
                  label={t('task:taskTransition:form:valueLabel')}
                  style={styles.flex}
                  required={mustCompleteWithValue}
                  onChange={(v: number | null) => {
                    onChange(v?.toString() ?? '')
                  }}
                  value={value === '' ? null : Number(value)}
                />
                {taskUnit ? (
                  <InputGroupText
                    a11yLabel="Completion Value Unit"
                    text={taskUnit}
                  />
                ) : null}
              </InputGroup>
            )}
            name="value"
          />
        )
      default:
        return null
    }
  }

  const isDeletable = getTaskIsDeletable(task)
  const onSubmitTask = useCallback(
    async (isInProgress: boolean = false) => {
      const inputData = getValues() as Inputs
      const newTaskValue = getFormValueOrTotalDosage({
        isMedication,
        patient,
        treatment,
        formValue: inputData.value,
        round,
      })

      if (
        treatment?.product?.type !== ProductType.MEDICATION &&
        treatment?.product?.type !== ProductType.IVFLUIDS
      ) {
        const vitalName = treatment?.name ?? ''
        const taskValue = inputData.value ?? ''
        const isOverflowed = checkValueIsOutsideOfRange(
          taskValue,
          callParamValue,
        )
        if (isOverflowed) {
          toast.notice(
            `${taskValue} is not in the range of the ${vitalName} call parameter`,
          )
        }
      }

      if (!task) {
        const taskInput = getCreateAndDoneOrProgressInput({
          isInProgress,
          newValue: newTaskValue,
          inputs: inputData,
        })
        return createAndCompleteTask?.(taskInput)
      }

      const originalTask = task
      const { updatedTask } = getUpdateTaskInput({
        originalTask,
        inputData,
        newTaskValue,
        organisationId,
        patientWeightUnit,
        shouldShowBillableToggle,
        treatment,
        shouldShowBackDate,
      })

      switch (action) {
        case Action.RESCHEDULE: {
          rescheduleTask(
            {
              ...originalTask,
              notes: updatedTask.notes ?? '',
            },
            inputData.newTaskDate,
          )
          break
        }
        case Action.COMPLETE: {
          const taskInput = getCompleteTaskInput({
            originalTask,
            updatedTask,
            isInProgress,
            datetimeGiven: inputData.backdate ? inputData.datetimeGiven : null,
          })
          updateTask(taskInput)
          break
        }
        case Action.EDIT: {
          const { taskInput } = getEditTaskInput({
            updatedTask,
            originalTask,
          })

          updateTask(taskInput)
          break
        }

        default:
          break
      }
      if (task) {
        toggleDialog()
      }
    },
    [
      action,
      createAndCompleteTask,
      getCompleteTaskInput,
      getCreateAndDoneOrProgressInput,
      getEditTaskInput,
      getValues,
      isMedication,
      organisationId,
      patient,
      patientWeightUnit,
      rescheduleTask,
      round,
      shouldShowBackDate,
      shouldShowBillableToggle,
      task,
      toggleDialog,
      treatment,
      updateTask,
      callParamValue,
    ],
  )

  const { getCacheTreatmentById } = useCacheTreatment()

  const onSubmit = async () => {
    const cacheTreatment = treatment?.id
      ? getCacheTreatmentById(treatment.id)
      : treatment

    if (shouldDisableSubmitButtonByApproval(cacheTreatment)) {
      toast.error(t('treatment:approval:failedToCompleteAction'))
      return
    }

    await onSubmitTask()
  }

  const onInProgress = async () => {
    const cacheTreatment = treatment?.id
      ? getCacheTreatmentById(treatment.id)
      : treatment

    if (shouldDisableStartButtonByApproval(cacheTreatment)) {
      toast.error(t('treatment:approval:failedToCompleteAction'))
      return
    }

    await onSubmitTask(true)
  }

  const onDelete = useCallback(() => {
    if (!isEmpty(task?.notes)) {
      toast.error(t('task:taskTransition.error.note'))
      return
    }
    if (!isEmpty(task?.highlight_colour)) {
      toast.error(t('task:taskTransition.error.highlightColor'))
      return
    }
    if (task?.cubex_transaction_status === CubexTransactionStatus.SUCCESS) {
      toast.error(t('task:taskTransition.error.cubex'))
      return
    }
    if (task) {
      deleteTask(task, isDeletable)
      toggleDialog()
    }
  }, [deleteTask, isDeletable, t, task, toggleDialog])

  const onSkip = () => {
    if (task) {
      const cacheTreatment = treatment?.id
        ? getCacheTreatmentById(treatment.id)
        : treatment

      if (shouldDisableSkipButtonByApproval(cacheTreatment)) {
        toast.error(t('treatment:approval:failedToCompleteAction'))
        return
      }

      const updateNotes = getValues('notes')
      const updateHighlightColor = getValues('highlightColor')
      const taskInput = {
        ...omit(task, [
          '_pending',
          'send_to_adaptor',
          'created_at',
          'start_at',
          'stop_at',
          'billing_reference',
          'updated_at',
          'updated_by',
          'attending_vet',
          'attending_department',
          'medicine_dosage_info',
          'cubex_transaction_status',
          'user_updated_at',
        ]),
        photo_urls: [],
        notes: updateNotes,
        highlight_colour: updateHighlightColor,
        status: StatusEnum.MISSED_ON_PURPOSE,
      }
      updateTask(taskInput)
      toggleDialog()
    }
  }

  const onSaveChanges = () => {
    const updateNotes = getValues('notes')
    const updateHighlightColor = getValues('highlightColor')
    const taskInput = {
      ...omit(task, [
        '_pending',
        'send_to_adaptor',
        'created_at',
        'start_at',
        'stop_at',
        'billing_reference',
        'updated_at',
        'updated_by',
        'attending_vet',
        'attending_department',
        'medicine_dosage_info',
        'status',
        'user_updated_at',
        'cubex_transaction_status',
      ]),
      notes: updateNotes,
      highlight_colour: updateHighlightColor,
    }
    updateTask(taskInput)
    toggleDialog()
  }

  useEffect(() => {
    if (isCompleteAction) {
      setValue('showValue', true, { shouldValidate: true })
    }
  }, [action, isCompleteAction, setValue])

  const showSaveNotesButton = getIsTaskMissedOrPendingOrDue(task?.status)

  const isSkippable = getIsTaskSkipDisabledWithMandatoryNotes(
    errors.notes?.message,
  )

  useImperativeHandle(
    ref,
    () => ({
      getTaskNotes: () => getValues('notes'),
      getTaskHighlightColor: () => getValues('highlightColor'),
      getTaskQtyBilled: () => getValues('qtyBilled'),
    }),
    [getValues],
  )

  const renderTaskDeleteButton = useMemo(() => {
    const permissionAction =
      task?.status === StatusEnum.MISSED
        ? PermissionAction.TASK__DELETE_MISSED
        : null
    return (
      <PermissionTextLink
        permissionAction={permissionAction}
        a11yLabel={t('task:taskTransition:delete:a11y')}
        text={t('task:taskTransition:delete:title')}
        onPress={onDelete}
        fontSize={14}
        disabled={
          task?.cubex_transaction_status === CubexTransactionStatus.SUCCESS
        }
        containerStyles={styles.linkBtnStyle}
      />
    )
  }, [onDelete, t, task?.cubex_transaction_status, task?.status])

  const currentUser = useUser()

  const doesUserNeedApproval =
    currentUser?.approvalRole === ApprovalRole.NEEDS_APPROVAL

  const { isTreatmentPending } = useApprovals()

  const isProgessTask = task?.status === StatusEnum.IN_PROGRESS

  const shouldDisableSubmitButtonByApproval = useCallback(
    (treatment: TreatmentChildLevel | null) => {
      if (!task) {
        return (
          (!!treatment && isTreatmentPending(treatment)) || doesUserNeedApproval
        )
      }

      switch (action) {
        case Action.COMPLETE:
          return !!treatment && isTreatmentPending(treatment)
        case Action.EDIT:
          return isProgessTask
            ? !!treatment && isTreatmentPending(treatment)
            : false
        case Action.RESCHEDULE:
        case Action.VIEW:
        default:
          return false
      }
    },
    [action, doesUserNeedApproval, isProgessTask, isTreatmentPending, task],
  )

  const shouldDisableSubmitButton = useMemo(() => {
    // existing disable logic
    if (shouldDisableSubmit) return true

    return shouldDisableSubmitButtonByApproval(treatment)
  }, [shouldDisableSubmit, shouldDisableSubmitButtonByApproval, treatment])

  const shouldDisableStartButtonByApproval = useCallback(
    (treatment: TreatmentChildLevel | null) => {
      // Task Create Drawer
      if (!task) {
        return (
          (!!treatment && isTreatmentPending(treatment)) || doesUserNeedApproval
        )
      }

      // Task Edit Drawer
      return !!treatment && isTreatmentPending(treatment)
    },
    [doesUserNeedApproval, isTreatmentPending, task],
  )

  const shouldDisableStartTaskButton = useMemo(() => {
    // existing disable logic
    if (shouldDisableSubmit) return true

    return shouldDisableStartButtonByApproval(treatment)
  }, [shouldDisableStartButtonByApproval, shouldDisableSubmit, treatment])

  const shouldDisableSkipButtonByApproval = useCallback(
    (treatment: TreatmentChildLevel | null) => {
      return !!treatment && isTreatmentPending(treatment)
    },
    [isTreatmentPending],
  )

  const valueIsValidQtyBilled = useMemo((): boolean => {
    if (
      isValueEqualQtyBilled ||
      !watchedValue ||
      isTaskBilled ||
      isNaN(Number(watchedValue)) ||
      !hasQtyBilledPermission ||
      !/^(?!0\d|$)\d*(\.\d{1,4})?$/.test(watchedValue)
    )
      return false

    return true
  }, [
    hasQtyBilledPermission,
    isTaskBilled,
    isValueEqualQtyBilled,
    watchedValue,
  ])

  const useValueForQtyBilledButtonStyle = useMemo(
    () => [
      styles.buttonStyle,
      valueIsValidQtyBilled ? {} : styles.disabledButtonStyle,
    ],
    [valueIsValidQtyBilled],
  )

  const initialsValueSectionContainerStyle = useMemo(() => {
    return isSmallToLargeDisplay
      ? styles.initialsValueContainerMobile
      : styles.initialsValueContainer
  }, [isSmallToLargeDisplay])

  return (
    <>
      <View
        style={[
          styles.container,
          {
            paddingBottom: isWeb && breakpoint.isLargeScreen ? 24 : 36,
          },
        ]}
      >
        {isObservation ? (
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextArea
                label={t('task:taskTransition:form:obs')}
                onChangeText={onChange}
                value={value}
                visibleLines={6}
                disabled={isReadOnlyAction}
                status={createErrorStatus(errors.notes?.message, true)}
              />
            )}
            name="notes"
          />
        ) : null}
        <View style={initialsValueSectionContainerStyle}>
          <View style={styles.flex}>{renderValueInput()}</View>
          {!!shouldShowBillableToggle && !isMedication ? (
            <View
              style={[
                !isSmallToLargeDisplay && styles.flex,
                styles.center,
                styles.useValueButtonContainer,
              ]}
            >
              {
                <PermissionPressable
                  onPress={handleUseValueAsQtyBilled}
                  disabled={!valueIsValidQtyBilled}
                  permissionAction={PermissionAction.TASK_BILLING__CHANGE}
                >
                  <View style={useValueForQtyBilledButtonStyle}>
                    <Text style={styles.buttonTextStyle}>
                      {t('task:taskTransition:form:useAsQtyBilled')}
                    </Text>
                    <View style={styles.leftMargin}>
                      <SvgArrowWithStem
                        orient="down"
                        width={15}
                        height={15}
                        color={dsColors.Contents.accent}
                      />
                    </View>
                  </View>
                </PermissionPressable>
              }
            </View>
          ) : null}
        </View>

        {mustCompleteWithInitials ? (
          <Controller
            control={control}
            render={({ field: { value, onBlur, onChange } }) => (
              <Status
                status={createErrorStatus(errors.assigned_user?.message, true)}
              >
                <TextInput
                  accessibilityLabel="Initials Input"
                  autoFocus={true}
                  disabled={isReadOnlyAction}
                  label={t('task:taskTransition:form:initials')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  required={mustCompleteWithInitials}
                  style={styles.flex}
                  value={value!}
                />
              </Status>
            )}
            name="assigned_user"
          />
        ) : null}
        <FormBreak />
        {isRescheduleAction ? (
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateTimePicker
                dialog={false}
                label={t('task:taskTransition:form:rescheduleTo')}
                onChange={onChange}
                value={value}
                warningInfo={{ type: WarningType.TASK }}
              />
            )}
            name="newTaskDate"
          />
        ) : null}

        {!!shouldShowBillableToggle ? (
          <BillingSection
            control={control}
            handleChangeIsBillable={handleIsBillable}
            handleChangeQtyBilled={handleQtyBilled}
            qtyBilledPermissionAction={PermissionAction.TASK_BILLING__CHANGE}
            isCubexBillable={!!treatment?.cubex_integration?.is_cubex_billable}
            cubexTransactionStatus={task?.cubex_transaction_status}
            isReadOnlyAction={isReadOnlyAction}
            qtyBilledErrorMessage={errors.qtyBilled?.message}
            disabled={isTaskBilled}
          />
        ) : null}
        <FormBreak />
        {shouldShowBackDate ? (
          <>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <SwitchInput
                  label={t('task:taskTransition:form:backdate')}
                  onChangeValue={onChange}
                  value={value}
                  disabled={isReadOnlyAction}
                />
              )}
              name="backdate"
            />
            {isBackdated ? (
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    dialog={false}
                    label={t('task:taskTransition:form:givenAt')}
                    onChange={onChange}
                    value={value}
                  />
                )}
                name="datetimeGiven"
              />
            ) : null}
          </>
        ) : null}
        {!isObservation && (
          <>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  label={t('task:taskTransition:form:notes')}
                  onChangeText={onChange}
                  value={value}
                  visibleLines={6}
                  disabled={isReadOnlyAction}
                  status={createErrorStatus(errors.notes?.message, true)}
                >
                  {showSaveNotesButton ? (
                    <SaveNotesAndColorButton action={onSaveChanges} />
                  ) : null}
                </TextArea>
              )}
              name="notes"
            />
          </>
        )}
        <Controller
          control={control}
          name="highlightColor"
          render={({ field: { onChange, value } }) => (
            <ColorPicker
              label={t('task:taskTransition:form:color')}
              options={Colors.task.highlightColor}
              selected={value}
              onChange={onChange}
            />
          )}
        />
        <FormBreak />
        <TaskFileAttachments
          images={task?.photo_urls ?? []}
          videos={task?.videos ?? []}
          objectKeyPrefix={objectKeyPrefix}
          onChangePhotos={handlePhotoChange}
          onChangeVideos={handleVideoChange}
          onUploading={setIsUploadingMedia}
          isUploading={isUploadingMedia}
          shouldShowUploadBtn={shouldShowUploadBtn}
          attachmentInfo={attachmentInfo(treatment, task)}
        />
        {!isReadOnlyAction && (
          <>
            <View
              style={[
                styles.buttonLayout,
                isSmallToLargeDisplay && styles.buttonLayoutSmall,
              ]}
            >
              <SubmitButton
                action={action}
                task={task}
                loading={isUploadingMedia}
                onSubmit={onSubmit}
                disabled={shouldDisableSubmitButton}
              />
              {showInProgress ? (
                <View style={styles.flex}>
                  <Button
                    color={Colors.white}
                    disabled={shouldDisableStartTaskButton}
                    onPress={onInProgress}
                    title={t('task:taskTransition:submit:inProgress')}
                    loading={isUploadingMedia}
                    containerStyle={styles.buttonContainer}
                    minWidth={buttonMinWidth}
                    style={[
                      styles.border,
                      styles.inProgressButtonBorder,
                      shouldDisableStartTaskButton &&
                        styles.disabledInProgressButtonBorder,
                    ]}
                    textStyle={
                      shouldDisableStartTaskButton
                        ? styles.disabledInProgressButtonText
                        : styles.inProgressButtonText
                    }
                  />
                </View>
              ) : null}
            </View>
            {isDeletable ? (
              <View style={styles.linkBtnsContainer}>
                <TextLink
                  disabled={
                    isSkippable || shouldDisableSkipButtonByApproval(treatment)
                  }
                  a11yLabel={t('task:taskTransition:skip:a11y')}
                  text={t('task:taskTransition:skip:title')}
                  onPress={onSkip}
                  fontSize={14}
                  color={Colors.blue}
                  containerStyles={styles.linkBtnStyle}
                />

                {renderTaskDeleteButton}
              </View>
            ) : null}
            {!!task && !isDeletable && (
              <PermissionResetButton
                a11yLabel={
                  getProductIsHealthStatusWeight(treatment?.product)
                    ? t('task:taskTransition:reset:weighta11y')
                    : undefined
                }
                permissionAction={PermissionAction.TASK__RESET}
                onReset={onReset}
                buttonText={t('task:taskTransition:reset:title')}
              />
            )}
          </>
        )}
        {!isFinalized && isTaskPastEditingTimeout ? (
          <InformativeMessage
            messageText={t('task:taskTransition:isPastTaskEditingTimeout', {
              timeout: taskEditingTimeoutValue,
            })}
          />
        ) : null}
        {!!activityLog && (
          <View style={styles.activityLogContainer}>{activityLog}</View>
        )}
      </View>
    </>
  )
}

export type TaskTransitionRef = {
  getTaskNotes: () => string
  getTaskHighlightColor: () => string | null
  getTaskQtyBilled: () => number | null
}

export const TaskTransition = forwardRef(TaskTransitionWithoutRef)

import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { View, StyleSheet, Animated, Easing, Platform } from 'react-native'
import { Layout } from '../components/Layout'
import { SingleSelect } from 'src/design-system/components/Selects'
import { DividerWithText } from '../components/DividerWithText'
import { useNavigation } from '@react-navigation/native'
import {
  Auth,
  AUTH_STATE,
  AUTH_WEIGHT,
  useAuth,
  userPinSessionService,
  userSessionService,
} from 'src/context/auth'
import { AuthHeader } from '../components/Header'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { PinInput } from '../components/PinInput'
import { sortList } from 'src/utils/sortList'
import { useTranslation } from 'react-i18next'
import { SubHeading } from 'src/design-system/components/Text'
import { ErrorCode } from 'src/context/auth/utils/type'
import { LoadingOverlay } from 'components/common/LoadingOverlay'
import { AuthPrimaryButton, AuthSecondaryButton } from '../components/Buttons'

const PIN_LENGTH = 5

export const LoginWithPin: React.FC<PropsWithChildren> = () => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const exAndSmallScreen = isExSmallScreen || isSmallScreen
  const [pin, setPin] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const { toggleAuthState } = useAuth()
  const animatedValue = useMemo(() => new Animated.Value(0), [])

  const usersOptions = useMemo(() => {
    const allUserPinSession = userPinSessionService.getAllUserPinSession()
    if (!allUserPinSession || allUserPinSession.length === 0) {
      return []
    }
    const allUserPinSessionArray = Object.values(allUserPinSession)
    const filteredPinsArray = allUserPinSessionArray.filter(pin => !!pin.pinId)
    const sortedUsers = sortList(filteredPinsArray)
    return sortedUsers.map(user => ({
      text: user.name,
      value: user.userId ?? '',
    }))
  }, [])

  const [selectedUser, setSelectedUser] = useState(usersOptions[0]?.value ?? '')

  const changeUser = (user: string) => {
    setSelectedUser(user)
  }

  const handleSignInWithEmail = async () => {
    if (userSessionService.hasV2Session()) {
      const result = await Auth.signOut()
      if (result?.errorCode) return
    }
    userSessionService.deleteUserSession()
    navigation.navigate(AUTH_STATE.LOGIN.toLowerCase())
  }

  const handleConfirmPin = useCallback(
    async (value?: string, onError?: () => void) => {
      setIsLoading(true)
      const user = userPinSessionService.getUserPinSession(selectedUser)

      // TODO: Handle error
      if (!user) {
        setIsLoading(false)
        return
      }

      const result = await Auth.signInWithPin(user, value ?? pin)

      if (result?.errorCode) {
        if (result.errorCode === ErrorCode.PIN_MAX_ATTEMPT) {
          navigation.navigate(AUTH_STATE.LOGIN.toLowerCase())
        }
        onError?.()
        setIsLoading(false)
        return
      }

      userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
      toggleAuthState(AUTH_STATE.UNLOCK_WITH_PIN, true)
    },
    [navigation, pin, selectedUser, toggleAuthState],
  )

  const startShake = useCallback(() => {
    animatedValue.setValue(0)
    Animated.timing(animatedValue, {
      toValue: 1,
      duration: 200,
      easing: Easing.linear,
      useNativeDriver: Platform.OS !== 'web',
    }).start()
  }, [animatedValue])

  const handlePinInput = useCallback(
    (value: string, reset: () => void) => {
      setPin(value)
      if (value.length === PIN_LENGTH) {
        handleConfirmPin(value, () => {
          startShake()
          reset()
        })
      }
    },
    [startShake, handleConfirmPin],
  )

  return (
    <Layout>
      <View
        style={[
          styles.container,
          exAndSmallScreen ? styles.exSmallContainer : null,
        ]}
      >
        <AuthHeader title={t('login:label.unlockWithPin')} alwaysShowLogo />
        {selectedUser ? (
          <>
            <SingleSelect
              label={'Select user'}
              options={usersOptions}
              selected={selectedUser}
              onChange={(user: string) => changeUser(user)}
              hideChevron={usersOptions.length === 1}
            />
            <Animated.View
              style={{
                alignItems: 'center',
                transform: [
                  {
                    translateX: animatedValue.interpolate({
                      inputRange: [0, 0.25, 0.5, 0.75, 1],
                      outputRange: [0, 20, 0, 20, 0],
                    }),
                  },
                ],
              }}
            >
              <LoadingOverlay isLoading={isLoading}>
                <PinInput
                  onChange={handlePinInput}
                  onConfirm={handleConfirmPin}
                />
              </LoadingOverlay>
            </Animated.View>
            <DividerWithText text="Or" />
            <AuthSecondaryButton
              onPress={handleSignInWithEmail}
              title={t('login:label.unlockWithEmail')}
            />
          </>
        ) : (
          <>
            <View style={styles.noPinContainer}>
              <SubHeading
                size="L"
                style={styles.noPinText}
                aria-label="No PINs available"
              >
                {t('login:label.noPinWarning')}
              </SubHeading>
              <SubHeading
                size="L"
                style={styles.noPinText}
                aria-label="Unlock with email"
              >
                {t('login:label.noPinSuggestion')}
              </SubHeading>
            </View>
            <AuthPrimaryButton
              onPress={handleSignInWithEmail}
              title={t('login:label.unlockWithEmail')}
            />
          </>
        )}
      </View>
    </Layout>
  )
}

const styles = StyleSheet.create({
  container: {
    gap: 48,
  },
  exSmallContainer: {
    flex: 0.8,
    justifyContent: 'flex-start',
  },
  noPinContainer: {
    marginTop: -40,
  },
  noPinText: {
    color: '#5D6066',
  },
})
